<template>
  <div id="about-page">
    <Navigation/>
    <Textree v-if="pages[pageIndex]=='textree'"/>
    <Values v-if="pages[pageIndex]=='values'"/>
    <Team v-if="pages[pageIndex]=='team'"/>
    <More @next="pageMove"/>
    <MobileMore/>
  </div>
</template>

<script>
import store from '@/store'

export default {
  components: {
    Navigation: () => import('@/components/nav'),
    More: () => import('@/components/more'),
    MobileMore: () => import('@/components/mobile-more'),
    Textree: () => import('./textree'),
    Values: () => import('./values'),
    Team: () => import('./team')
  },
  data: () => ({
    pages: [
      'textree',
      'values',
      'team'
    ],
    pageIndex: 0
  }),
  mounted() {
    store.commit('SET_NAV_PAGE', 'about')
    store.commit('SET_NAV_THEME', 'dark')
    store.commit('SET_MORE_THEME', 'dark')
    store.commit('SET_MORE_PAGES', this.pages)
    store.commit('SET_MORE_HAS_SCROLL', true)
  },
  methods: {
    pageMove: function (index) {
      this.pageIndex = index
    }
  }
}
</script>
<style>

</style>
<style scoped>
div#about-page {
  position: absolute;
  width: 100%;
  height: calc(100% - 66px - 64px);
  top: 66px;
  bottom: 64px;
  overflow-y: scroll;
}
</style>

<style>
@media(max-width: 1024px) {
  #plusfriend-chat-button {
    bottom: 12px;
  }
}

@media(max-width: 768px) {
  #plusfriend-chat-button {
    bottom: 64px;
  }
}
</style>
